<template>
  <div class="product-recomendation-wrapper">
    <div v-if="isLoadingFinish" class="row">
      <template v-for="(item, key) in productRecomendation">
        <product :key="key" :item="item" :linkdetail="productDetailLink"></product>
      </template>

      <!-- <router-link class="col-xs-4 col" :to="`${productDetailLink}/${item.id_product}`" v-for="(item, key) in productRecomendation" :key="key">
				<div class="product-recomendation">
					<span class="product-recomendation__discount" v-if="item.discount_type == 'percentage'">{{item.discount}}%</span>

					<div class="product-recomendation__thumb">
						<img :src="item.image_cover" class="product-recomendation__img" />
					</div>

					<div class="product-recomendation__info">
						<h2 class="product-recomendation__brand-name">{{ item.manufacturer_name }}</h2>
						<p class="product-recomendation__product-name">{{ item.product_name }}</p>

						<div class="group-price">
							<h5 class="product-recomendation__price" v-if="item.discount_type && item.price_after_discount < item.price">Rp {{item.price | currency}}</h5>

							<h5 class="product-recomendation__price-line-through" v-if="item.price_after_discount > 0 && item.discount_type == 'percentage'">SAVE -Rp. {{(item.price - item.price_after_discount) | currency}}</h5>

							<h5 class="product-recomendation__price-total" v-if="item.price_after_discount < item.price && item.price_after_discount && item.discount_type">Rp {{ item.price_after_discount | currency }}</h5>
							<h5 class="product-recomendation__price-total" v-else>Rp {{ item.price | currency }}</h5>
						</div>
					</div>

					<div class="product-recomendation__rating">
						<div class="rating--uniform timeline-review">
						  <div
						    class="rate-star--uniform"
						    :class="'rate-star--uniform-' + $options.filters.rating(item.rating)">

						    <span></span>
						    <span></span>
						    <span></span>
						    <span></span>
						    <span></span>
						  </div>
						  <span class="total-review">({{ item.total_review }})</span>
						</div>
					</div>
				</div>
			</router-link> -->
    </div>
    <div v-else class="state-loading">
      <div id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>
    </div>
  </div>
</template>

<script>
import product from '~/src/components/global/product-display/product-display-list';

export default {
  name: 'ProductRecomendation',
  components: {
    product,
  },
  props: {
    isLoadingFinish: {
      required: true,
      type: Boolean,
    },
    productRecomendation: {
      required: true,
      type: Array,
    },
    productDetailLink: {
      required: true,
      type: String,
    },
  },
};
</script>
