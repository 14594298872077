<template>
  <div class="content-oops">
    <Logout />
    <br />
    <img src="/static/img/mask-station/logo.png" class="logo" />
    <img src="/static/img/mask-station/icon-fill-beauty-profile.png" class="icon-sad" />

    <h2 class="heading">NO RECOMMENDATION :(</h2>
    <p class="text-sorry">Sorry we couldn't find any recommendation.<br />Please complete your Beauty Profile.</p>
    <p class="text-worry">
      Don't worry our Beauty Assistant will be happy to help you, or please complete your beauty profile via web
      <a :href="`${$store.state.SOCO_WEB_URL}/beauty-profile`" target="_blank">soco.id</a> or download SOCO mobile app
      on Android or iOS.
    </p>

    <div class="box">
      <div class="box__paraf">Aplikasi mobile <span class="bolder">SOCO</span> tersedia di iOS dan Android</div>
      <div class="group-btn text-center">
        <a href="" class="btn btn-dark btn-download btn-download--appstore"></a>
        <a href="" class="btn btn-dark btn-download btn-download--playstore"></a>
      </div>
    </div>
  </div>
</template>

<script>
// import LogoutState from '@/components/socomask/global/logout-state';
import Logout from '@/components/socomask/global/logout';

export default {
  name: 'NotCompleteBeautyProfile',
  components: {
    // LogoutState,
    Logout,
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    isLogin() {
      return this.$SO.size(this.auth.userProfile);
    },
    expired_token() {
      return Cookies.get('expired_token');
    },
  },
  created() {
    if (!this.$SO.size(this.auth.userProfile) || !this.expired_token) {
      this.$store.dispatch('checkLogin', Cookies.get('expired_token'));
    }
  },
};
</script>
