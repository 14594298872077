<template>
  <div
    class="page--mask-station beauty-profile"
    :class="{ 'not-complete-beautyprofile': auth && !auth.userProfile.beauty.length }"
  >
    <div v-if="isLogin">
      <template v-if="auth.processingLogout === false">
        <div v-if="auth && auth.userProfile.beauty.length">
          <Header />

          <div class="body">
            <div class="container-mask-station">
              <Logout />
              <CompleteBeautyProfile />
            </div>
          </div>
        </div>
        <div v-else class="container-mask-station">
          <NotCompleteBeautyProfile />
        </div>
      </template>
      <template v-else>
        <LogoutState />
      </template>
    </div>
    <div v-else>
      <div id="loaderspinwrap" class="loaderspinwrap" style="padding: 30px 0"><div class="loaderspin"></div></div>
    </div>
  </div>
</template>

<script>
import CompleteBeautyProfile from './snippet/complete-beauty-profile';
import NotCompleteBeautyProfile from './snippet/notcomplete-beauty-profile';
import Header from '@/components/socomask/global/header';
import LogoutState from '@/components/socomask/global/logout-state';
import Logout from '@/components/socomask/global/logout';

export default {
  name: 'MaskStationBeautyProfile',
  components: {
    CompleteBeautyProfile,
    NotCompleteBeautyProfile,
    Header,
    LogoutState,
    Logout,
  },
  computed: {
    auth() {
      return this.$store.state.auth;
    },
    isLogin() {
      return this.$SO.size(this.auth.userProfile);
    },
    expired_token() {
      return Cookies.get('token');
    },
  },
  created() {
    if (!this.$SO.size(this.auth.userProfile) || !this.expired_token) {
      this.$store.dispatch('checkLogin', { token: Cookies.get('token'), home: 'maskstationHome' });
    }
  },
};
</script>
