<template>
  <div>
    <div class="box-user">
      <p class="box-user__greeting">Recommended for You!</p>
    </div>

    <p class="beauty-profile__copywriting">Rekomendasi masker berdasarkan Beauty Profile kamu</p>

    <ProductRecomendation
      :is-loading-finish="isLoadingFinish"
      :product-recomendation="recomendedMask || []"
      :product-detail-link="`/mask-station/product-detail`"
    />

    <p class="beauty-profile__text">Can't look for your need? Ask our <span class="bolder">Beauty Assistants!</span></p>
  </div>
</template>

<script>
import ProductRecomendation from '@/components/global/product-recomendation/product-recomendation';

export default {
  name: 'CompleteBeautyProfile',
  components: {
    ProductRecomendation,
  },
  data() {
    return {
      isLoadingFinish: false,
      recomendedMask: '',
    };
  },
  created() {
    this.getRecomendedMask();
  },
  methods: {
    getRecomendedMask() {
      this.isLoadingFinish = false;
      this.$MS_SOCO_PUBLIC_API_URL
        .get(`/products/beauty-profile`, {
          params: {
            limit: 6,
          },
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            this.recomendedMask = response.data.data;
          } else {
            this.recomendedMask = [];
          }
          this.isLoadingFinish = true;
        })
        .catch((error) => {
          this.recomendedMask = [];
          this.isLoadingFinish = true;
        });
    },
    getToken() {
      if (Cookies.get('token') && Cookies.get('token') != undefined) {
        return Cookies.get('token');
      }
    },
  },
};
</script>

<style>
.product-recomendation-wrapper .row {
  display: flex;
  flex-wrap: wrap;
}
</style>
